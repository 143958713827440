import React, { useState, useRef, useEffect, useContext } from "react";
import ModifyStyle from "../../components/plan/PlanStyle";
import ClientService from "../../services/ClientService";
import PlanService from "../../services/PlanService";
import CurrentClientContext from "../../providers/CurrentClientProvider";
import MessageContext from "../../providers/GlobalTip";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import FuturePlanEditModal from "../modals/plans/FuturePlanEditModal";
import {
  TextField,
  Grid,
  Button,
  Box,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  LivePlanAnnualForm,
  LivePlanTableData,
  colorMap,
  LivePlanTableRow,
  LivePlanTableDataMap,
} from "../../models/LivePlanModel";
import cloneDeep from "lodash.clonedeep";
import _ from "underscore";
import __ from "lodash";
import numeral from "numeral";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import PlanCalculatorService from "../../services/PlanCalculatorService";
import { useNavigate } from "react-router-dom";
import BigTable from "../../components/plan/BigTable";
import moment from "moment";
import PlanCopyFromModal from "./PlanCopyFromModal";
import PlanCopyToModal from "./PlanCopyToModal";
import {
  HotKeysContext,
  HotKeysProvider,
} from "../../providers/HotKeysProvider";
import PlanChart from "./PlanChart";
import MassCopyPlanUploadModal from "../modals/plans/MassCopyPlanUploadModal";

function LivePlanEdit(props) {
  const {
    clientBasicData,
    optionValue,
    onOptionValueChange,
    client,
    onPlanDataLoad,
    createFuturePlanTrigger,
    onFutureCreated,
    createFuturePlan,
    removeFuturePlan,
    showHeaderContainer,
    setShowHeaderContainer
  } = props;
  const [loading, setLoading] = useState(true);
  const [targetsLoading, setTargetsLoading] = useState(true);
  const [loadError, setLoadError] = useState();
  const [planData, setPlanData] = useState();
  const [targets, setTargets] = useState();
  const [salesModelType, setSalesModelType] = useState(0);
  const [imuModelType, setImuModelType] = useState(0);
  let [annualForm, setAnnualForm] = useState({});
  const [displayMonths, setDisplayMonths] = useState([]);
  let [tableData, setTableData] = useState(new Map());
  const otherLocationClassId = useRef();
  const currentUpdateRef = useRef(null);
  const [singleSaving, setSingleSaving] = useState(false);
  const messageContext = MessageContext.useMessage();
  const [buildingAll, setBuildingAll] = useState(false);
  const [buildingAllLocation, setBuildingAllLocation] = useState(false);
  const [loadingHistoricalData, setLoadingHistoricalData] = useState(false);
  const [historicalPeriodDatas, setHistoricalPeriodDatas] = useState([]);
  const [displayCopyFromModal, setDisplayCopyFromModal] = useState(false);
  const [copyFromLoading, setCopyFromLoading] = useState(false);
  const [displayCopyToModal, setDisplayCopyToModal] = useState(false);
  const [markdownAutoUpdate, setMarkdownAutoupdate] = useState(false);
  const intervalRef = useRef();
  const isCopy = JSON.parse(localStorage.getItem('showLoadCopyContainer'))
  const isTarget = JSON.parse(localStorage.getItem('showTargetContainer'))
  const isGraph = JSON.parse(localStorage.getItem('showGraphContainer'))
  const [showLoadCopyContainer, setShowLoadCopyContainer] = useState(isCopy === null ? true : isCopy);
  const [showTargetContainer, setShowTargetContainer] = useState(isTarget === null ? true : isTarget);
  const [showGraphContainer, setShowGraphContainer] = useState(isGraph === null ? true : isGraph);
  const [costOfPurchase, setCostOfPurchase] = useState(0);
  const triggerRecalculate = useRef(false);
  const [futurePlanModalOpen, setFuturePlanModalOpen] = useState(false);
  const [displayMassCopyPlanModal, setDisplayMassCopyPlanModal] = useState(false);

  const [tabs, setTabs] = useState([
    {
      label: "Target",
      titles: ["Target", "Actual", "Plan"],
      values: [
        ["Cash", 0, 0],
        ["GMROI", 0, 0],
        ["CMROI", 0, 0],
        ["MMU", 0, 0],
      ],
    },
    {
      label: "Trend",
      titles: ["", "Actual", "Trend", "Plan", "% of Plan"],
      values: [
        ["Sales", 0, 0, 0, 0],
        ["Markdowns", 0, 0, 0, 0],
        ["Inventory", 0, 0, 0, 0],
        ["Calc", "", 0, "", ""],
        ["Calc 3.0", "", 0, "", ""],
        ["Linear", "", 0, "", ""],
        ["L12", "", 0, "", ""],
      ],
    },
    {
      label: "30",
      titles: ["Progress", "Actual", "Plan"],
      values: [
        ["Cash", 0, 0],
        ["Inflow", 0, 0],
        ["Sales", 0, 0],
        ["Markdowns", 0, 0],
        ["Freshness", 0, ""],
      ],
    },
    {
      label: "60",
      titles: ["Progress", "Actual", "Plan"],
      values: [
        ["Cash", 0, 0],
        ["Inflow", 0, 0],
        ["Sales", 0, 0],
        ["Markdowns", 0, 0],
        ["Freshness", 0, ""],
      ],
    },
    {
      label: "90",
      titles: ["Progress", "Actual", "Plan"],
      values: [
        ["Cash", 0, 0],
        ["Inflow", 0, 0],
        ["Sales", 0, 0],
        ["Markdowns", 0, 0],
        ["Freshness", 0, ""],
      ],
    },
  ]);
  const [tab, setTab] = useState(tabs[0]?.label);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem('showLoadCopyContainer', JSON.stringify(showLoadCopyContainer));
    localStorage.setItem('showTargetContainer', JSON.stringify(showTargetContainer));
    localStorage.setItem('showGraphContainer', JSON.stringify(showGraphContainer));
  }, [showLoadCopyContainer, showTargetContainer, showGraphContainer]);

  useEffect(() => {
    return () => {
      // componentwillunmount in functional component.
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    loadLivePlanDetail();
  }, [clientBasicData]);

  useEffect(() => {
    reloadTargetTabs();
  }, [targets]);

  useEffect(() => {
    if (planData) {
      initPlanData();
      onPlanDataLoad(planData);
    }
  }, [planData]);

  useEffect(() => {
    if (tableData && !_.isEmpty(annualForm)) {
      updateTargetTableDataFromPlanData();
      updateCostOfPurchase();
      if (triggerRecalculate.current) {
        recalculateAllData();
      }
    }
  }, [tableData, annualForm]);

  useEffect(() => {
    if (createFuturePlanTrigger) {
      updatePlan(true);
    }
  }, [createFuturePlanTrigger]);

  const toggleCopyPanel = () => {
    setShowLoadCopyContainer(showLoadCopyContainer => !showLoadCopyContainer);
  }
  const toggleTargetPanel = () => {
    setShowTargetContainer(showTargetContainer => !showTargetContainer);
  }
  const toggleGraphPanel = () => {
    setShowGraphContainer(showGraphContainer => !showGraphContainer);
  }
  const toggleHeaderPanel = () => {
    setShowHeaderContainer(showHeaderContainer => !showHeaderContainer);
  }

  const initPlanData = () => {
    const _annualForm = cloneDeep(LivePlanAnnualForm);
    initAnnualForm(_annualForm);
    setAnnualForm(_annualForm);
    const _display_months = planData.display_months.map((item) => {
      const [month, year] = item.monthDisplay.split(" ");
      return { ...item, month, year };
    });
    const basePlanTableData = cloneDeep(LivePlanTableDataMap);
    planData.actualdetails[0].InventoryTotal_PrevMon = parseFloat(
      planData.planning_month_actual_inv_bom
    );
    for (const [key, data] of basePlanTableData) {
      if (data instanceof Map) {
        for (const [_, val] of data) {
          val.innerData = planData[val.sourceKey].map((item) => {
            const value = +(item[val.key] ?? 0);
            return {
              value: value,
              orig: value,
              current: value,
            };
          });
        }
      }
    }
    validateMarkdownActuals(basePlanTableData);

    setTableData(basePlanTableData);
    setDisplayMonths(_display_months);
    setSalesModelType(planData.SalesModelType);
    setImuModelType(planData.ImuModelType);
  };

  const validateMarkdownActuals = (basePlanTableData) => {
    let actualMarkdownsPerc = basePlanTableData
      .get("Markdowns")
      .get("MarkdownsPercent");
    let actualMarkdownsPercSummary = actualMarkdownsPerc.summary;
    if (actualMarkdownsPercSummary > 100) {
      let actualMarkdownsQty = basePlanTableData
        .get("Markdowns")
        .get("Markdowns");
      let actualMarkdownsQtySummary = actualMarkdownsQty.summary.toFixed(0);
      /* Iterate through the actualMarkdownsPerc values and assign new values of Percenatage values of value / summary *100  */
      let sumMPerc = 0;
      actualMarkdownsPerc.innerData = actualMarkdownsQty.innerData.map(
        (val, index) => {
          const value = +(
            (val.value / actualMarkdownsQtySummary) *
            100
          ).toFixed(2);
          sumMPerc += value;
          return { ...val, value };
        }
      );
      actualMarkdownsPerc.summary = (Math.floor(sumMPerc) * 1.0).toFixed(2);
    }
  };
  const loadLivePlanDetail = async (clearCache = false) => {
    try {
      setLoading(true);
      setTargetsLoading(true);
      let data;
      // let data = await PlanService.getLivePlanDetailClearCache(client.Id, clientBasicData.current_plan.plan_id, clientBasicData.current_plan.plan_version)
      if (clearCache) {
        data = await PlanService.getLivePlanDetailClearCache(
          client.Id,
          clientBasicData.current_plan.plan_id,
          clientBasicData.current_plan.plan_version
        );
      } else {
        data = await PlanService.getLivePlanDetail(
          client.Id,
          clientBasicData.current_plan.plan_id,
          clientBasicData.current_plan.plan_version
        );
      }
      data.actualdetails[0].InventoryBom = data.planning_month_actual_inv_bom;
      setPlanData(data);
      PlanCalculatorService._planData = data;

      setLoading(false);

      // load targets
      let targets;
      if (clearCache) {
        targets = await PlanService.getLivePlanDetailTargetsClearCache(
          client.Id,
          clientBasicData.current_plan.plan_id,
          clientBasicData.current_plan.plan_version
        );
      } else {
        targets = await PlanService.getLivePlanDetailTargets(
          client.Id,
          clientBasicData.current_plan.plan_id,
          clientBasicData.current_plan.plan_version
        );
      }

      // set targets on data
      setTargets(targets);
      setTargetsLoading(false);
    } catch (e) {
      setLoadError(e.response?.data?.message);
    }
  };

  const initAnnualForm = (form = {}) => {
    for (const key in form) {
      if (planData.hasOwnProperty(key)) {
        const value = +planData[key];
        form[key].value = value;
        form[key].current = value;
        form[key].orig = value;
        if (form[key].chipProps) {
          initAnnualForm(form[key].chipProps);
        }
      }
    }
  };

  const onTabChange = (value) => {
    value !== tab && setTab(value);
  };

  const removeCommas = (str) => {
    while (str?.search(",") >= 0) {
      str = (str + "").replace(",", "");
    }
    return str || "0";
  };

  const numberWithCommas = (num) => {
    if (!isNaN(num)) {
      num = parseInt(num);
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "0";
  };

  const updateAnnualForm = (key, updateParams) => {
    const item = annualForm[key];
    setAnnualForm({
      ...annualForm,
      [key]: { ...item, ...updateParams },
    });
  };

  const changeAnnualSales = (params, form) => {
    const smt = parseFloat(params.smt);
    let annualSalesAmt = 0;
    switch (smt) {
      case 0:
        annualSalesAmt = params.value;
        break;
      case 1:
      case 2:
      case 3:
      case 4:
        annualSalesAmt = params.current;
        break;
    }
    const _annualForm = form ?? cloneDeep(annualForm);
    const _tableData = cloneDeep(tableData);
    const previousAnnualSalesAmt = _annualForm.SalesAnnualPlan.current;
    if (annualSalesAmt != previousAnnualSalesAmt) {
      _annualForm.SalesAnnualPlan.value = annualSalesAmt;
      _annualForm.SalesAnnualPlan.current = annualSalesAmt;
      const annualMarkdownAmt = _annualForm.MarkdownsAnnualPercentPlan.value;
      const md_per = annualMarkdownAmt / 100;
      const an_sales = annualSalesAmt;
      const md_amt = an_sales * md_per;
      const markdownsPercentPlan = _tableData
        .get("Markdowns")
        .get("MarkdownsPercentPlan");
      const markdownsPlan = _tableData.get("Markdowns").get("MarkdownsPlan");
      const stockToSalesPlan = _tableData
        .get("Inventory")
        .get("StockToSalesPlan");
      const inventoryBomPlan = _tableData
        .get("Inventory")
        .get("InventoryBomPlan");
      // updateMarkdownsPlan
      markdownsPlan.innerData = markdownsPlan.innerData.map((val, index) => {
        const value = +(
          md_amt *
          (markdownsPercentPlan.innerData[index].value / 100)
        ).toFixed(0);
        return { ...val, value };
      });
      // updateSalesPlan
      const salesPercentPlan = _tableData.get("Sales").get("SalesPercentPlan");
      const salesPlan = _tableData.get("Sales").get("SalesPlan");
      salesPlan.innerData = salesPlan.innerData.map((val, index) => {
        const value = +(
          (an_sales * salesPercentPlan.innerData[index].value) /
          100
        ).toFixed(0);
        return { ...val, value };
      });
      // updateInventoryBomPlan
      inventoryBomPlan.innerData = inventoryBomPlan.innerData.map(
        (val, index) => {
          const sales = salesPlan.innerData[index].value;
          const ss = stockToSalesPlan.innerData[index].value;
          const inv = +(sales * ss).toFixed(0);
          return { ...val, value: inv, current: inv };
        }
      );
      PlanCalculatorService.update13thMonth(_tableData);
      PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
      PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
      PlanCalculatorService.balanceInflow(_tableData, _annualForm);
      setTableData(_tableData);
      setAnnualForm(_annualForm);
      setSalesModelType(smt);
    }
  };

  const changeAnnualMarkdowns = (params) => {
    const annualMarkdownAmt = params.value;
    const _annualForm = cloneDeep(annualForm);
    const _tableData = cloneDeep(tableData);
    const previousMarkdownAmt = _annualForm.MarkdownsAnnualPercentPlan.current;
    if (annualMarkdownAmt != previousMarkdownAmt) {
      _annualForm.MarkdownsAnnualPercentPlan.value = annualMarkdownAmt;
      _annualForm.MarkdownsAnnualPercentPlan.current = annualMarkdownAmt;
      const md_per = annualMarkdownAmt / 100;
      const an_sales = _annualForm.SalesAnnualPlan.value;
      const md_amt = an_sales * md_per;
      const markdownsPercentPlan = _tableData
        .get("Markdowns")
        .get("MarkdownsPercentPlan");
      const markdownsPlan = _tableData.get("Markdowns").get("MarkdownsPlan");
      markdownsPlan.innerData = markdownsPlan.innerData.map((val, index) => {
        const md_per_plan = markdownsPercentPlan.innerData[index].value;
        const new_md_plan = +(md_amt * (md_per_plan / 100)).toFixed(0);
        return { ...val, value: new_md_plan };
      });
      PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
      PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
      setTableData(_tableData);
      setAnnualForm(_annualForm);
      updateCalcTrendV3Button(_tableData, _annualForm);
    }
  };

  const changeAnnualTurn = (params) => {
    const _annualForm = cloneDeep(annualForm);
    const _tableData = cloneDeep(tableData);
    const turnAmt = params.value;
    const previousTurn = _annualForm.TurnPlan.current;
    if (turnAmt != previousTurn) {
      _annualForm.TurnPlan.current = turnAmt;
      if (turnAmt == 0 || turnAmt == "") {
        const inventoryBomPlan = _tableData
          .get("Inventory")
          .get("InventoryBomPlan");
        const stockToSalesPlan = _tableData
          .get("Inventory")
          .get("StockToSalesPlan");
        inventoryBomPlan.innerData = inventoryBomPlan.innerData.map(
          (val, index) => ({ ...val, value: 0, current: 0 })
        );
        stockToSalesPlan.innerData = stockToSalesPlan.innerData.map(
          (val, index) => ({ ...val, value: 0, current: 0 })
        );
        PlanCalculatorService.update13thMonth(_tableData);
        PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
        PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
        PlanCalculatorService.updateTurn(_tableData, _annualForm);
        setTableData(_tableData);
      } else if (turnAmt < 0) {
        _annualForm.TurnPlan.value = previousTurn;
      } else {
        _annualForm.TurnPlan.value = turnAmt;
        const inventoryBomPlan = _tableData
          .get("Inventory")
          .get("InventoryBomPlan");
        const annualSalesAmt = _annualForm.SalesAnnualPlan.value;
        const avgInv = annualSalesAmt / turnAmt;
        let origAvgInv = 0;
        if (previousTurn > 0) {
          origAvgInv = annualSalesAmt / previousTurn;
        }
        let turnAdd = false;
        if (previousTurn > turnAmt || previousTurn == 0) {
          turnAdd = true;
        }
        let avgMonInvChange = origAvgInv - avgInv;
        let nonZeroCnt = 0;
        let zeroInv = 0;
        do {
          nonZeroCnt = 0;
          if (zeroInv > 0) {
            inventoryBomPlan.innerData.forEach((val, index) => {
              if (val.value > 0) {
                nonZeroCnt = nonZeroCnt + 1;
              }
            });
            avgMonInvChange = zeroInv / nonZeroCnt;
            zeroInv = 0;
          }
          const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
          inventoryBomPlanInnerData.forEach((val, index) => {
            const origValue = val.value;
            if (origValue > 0 || (previousTurn == 0 && index < 12)) {
              let newVal = 0;
              if (turnAdd) {
                newVal = origValue + Math.abs(avgMonInvChange);
              } else {
                newVal = origValue - Math.abs(avgMonInvChange);
                if (newVal < 0) {
                  zeroInv = zeroInv + Math.abs(newVal);
                  nonZeroCnt = 1;
                  newVal = 0;
                }
              }
              const value = newVal.toFixed(0);
              inventoryBomPlanInnerData[index].value = +value;
              inventoryBomPlanInnerData[index].current = +value;
            }
          });
          inventoryBomPlan.innerData = inventoryBomPlanInnerData;
        } while (nonZeroCnt > 0 && zeroInv > 0);
        PlanCalculatorService.updateStockToSales(_tableData);
        PlanCalculatorService.update13thMonth(_tableData);
        PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
        PlanCalculatorService.updateTurn(_tableData, _annualForm);
        setTableData(_tableData);
      }
      setAnnualForm(_annualForm);
    }
  };

  const changeAnnualImu = (params) => {
    const _annualForm = cloneDeep(annualForm);
    const imt = parseFloat(params.imt);
    let imuAmt = 0;
    switch (imt) {
      case 0:
        imuAmt = params.value;
        break;
      case 1:
      case 2:
        imuAmt = params.current;
        break;
    }
    const previousImu = _annualForm.ImuPlan.current;
    if (imuAmt != previousImu) {
      const _tableData = cloneDeep(tableData);
      _annualForm.ImuPlan.value = imuAmt;
      PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
      setAnnualForm(_annualForm);
      setTableData(_tableData);
    }
  };

  const getKFlow = () => {
    const _tableData = cloneDeep(tableData);
    const _annualForm = cloneDeep(annualForm);
    PlanCalculatorService.getKFlow(_tableData, _annualForm);
    setTableData(_tableData);
    setAnnualForm(_annualForm);
  };

  const onAnnualFormBlur = (key, params) => {
    currentUpdateRef.current = key;
    if (key === "SalesAnnualPlan") {
      changeAnnualSales(params);
    } else if (key === "MarkdownsAnnualPercentPlan") {
      changeAnnualMarkdowns(params);
    } else if (key === "TurnPlan") {
      changeAnnualTurn(params);
    } else if (key === "ImuPlan") {
      changeAnnualImu(params);
    }
  };

  const updateTableForm = (e, key, innerKey, colIndex) => {
    const _tableData = cloneDeep(tableData);
    let value = "";
    if (!isNaN(e.target.value) && e.target.value) {
      value = e.target.value;
    }
    const _row = _tableData.get(key).get(innerKey);
    const updatedInnerData = _row.innerData;
    updatedInnerData[colIndex].value = value;
    _row.innerData = updatedInnerData;
    setTableData(_tableData);
  };

  const loadPlan = (type) => {
    loadLivePlanDetail();
  };

  const onBlurTableForm = (tableKey, rowKey, colIndex) => {
    if (rowKey === "SalesPercentPlan") {
      salesUpdate(tableKey, rowKey, colIndex);
    } else if (rowKey === "StockToSalesPlan") {
      stocktosalesplanUpdate(tableKey, rowKey, colIndex);
    } else if (rowKey === "InventoryBomPlan") {
      inventoryUpdate(tableKey, rowKey, colIndex);
    } else if (rowKey === "MarkdownsPercentPlan") {
      markdownsUpdate(tableKey, rowKey, colIndex);
    }
  };

  const salesUpdate = (tableKey, rowKey, colIndex, bulkUpdate = false) => {
    const _tableData = cloneDeep(tableData);
    const _annualForm = cloneDeep(annualForm);
    const targetRow = _tableData.get(tableKey).get(rowKey);
    const targetRowInnerData = targetRow.innerData;
    const salesPlan = _tableData.get("Sales").get("SalesPlan");
    const salesPlanVsHistorical = _tableData
      .get("Sales")
      .get("sales_plan_vs_historical").innerData;
    const salesPlanInnerData = salesPlan.innerData;
    // Specifies to update a column
    const { value, current } = targetRowInnerData[colIndex];
    const annualSalesAmt = annualForm.SalesAnnualPlan.value;
    targetRowInnerData[colIndex].current = value;
    salesPlanInnerData[colIndex].value = +(
      (annualSalesAmt * value) /
      100
    ).toFixed(0);
    salesPlanVsHistorical[colIndex].value = planData.plandetails[colIndex]
      .avg_sales_plan
      ? (
          (salesPlanInnerData[colIndex].value /
            planData.plandetails[colIndex].avg_sales_plan) *
          100
        ).toFixed(2)
      : 0;
    salesPlan.innerData = salesPlanInnerData;
    PlanCalculatorService.updateStockToSales(_tableData);
    PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
    PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
    updateCalcTrendButton(_tableData, _annualForm);
    updateCalcTrendV3Button(_tableData, _annualForm);
    if (!bulkUpdate) {
      setTableData(_tableData);
      setAnnualForm(_annualForm);
    } else {
      tableData = _tableData;
      annualForm = _annualForm;
    }
  };

  const updateCalcTrendButton = (tableData, form) => {
    let salespct = 0;
    let sales = 0;
    let count = 0;
    const salesPercentPlan = tableData.get("Sales").get("SalesPercentPlan");
    const salesPercentPlanInnerData = salesPercentPlan.innerData;
    [...salesPercentPlanInnerData].reverse().forEach((val, index) => {
      if (val.value >= 4 && count < 3) {
        count = count + 1;
        salespct = salespct + val.value * 1;
        sales = sales + planData.actualdetails[index].Sales * 1;
      }
    });
    let calc_trend = +(sales / (salespct / 100)).toFixed(0);
    const annual_sales_calc_trend =
      form.SalesAnnualPlan.chipProps.annual_sales_calc_trend;
    annual_sales_calc_trend.value = calc_trend;
    annual_sales_calc_trend.current = calc_trend;
  };

  const updateCalcTrendV3Button = (tableData, form, futureTrend = false) => {
    const _amount = calcTrendV3(tableData, form);
    form.SalesAnnualPlan.chipProps.calc_trend_sales_3.value = _amount;
    form.SalesAnnualPlan.chipProps.calc_trend_sales_3.current = _amount;
  };

  const calcTrendV3 = (tableData, form, futureTrend = false) => {
    const actualdetails = planData.actualdetails;
    const salesPercentPlan = tableData.get("Sales").get("SalesPercentPlan");
    let data = [];
    let calc = [];
    let annual_markdown = parseFloat(form.MarkdownsAnnualPercentPlan.value);
    let count = 1;
    if (
      futureTrend > 0 &&
      parseFloat(
        tableData.get("Sales").get("SalesPercentPlan").innerData[0]?.value
      ) >= 3
    ) {
      let md =
        parseInt(planData.trend["MarkdownsTrend"]) /
        parseInt(planData.trend["SalesTrend"]);
      let sales =
        parseInt(planData.trend["SalesTrend"]) -
        parseInt(planData.trend["SalesTrend"]) *
          Math.abs(md - annual_markdown / 100);
      if (md > annual_markdown / 100 && sales > 0) {
        data[count] = [];
        data[count]["Sales"] = sales;
        data[count]["SalesPercentPlan"] =
          parseFloat(
            tableData.get("Sales").get("SalesPercentPlan").innerData[0].value
          ) / 100;
        count++;
      } else if (md <= annual_markdown / 100) {
        data[count] = [];
        data[count]["Sales"] = parseInt(planData.trend["SalesTrend"]);
        data[count]["SalesPercentPlan"] =
          parseFloat(
            tableData.get("Sales").get("SalesPercentPlan").innerData[0].value
          ) / 100;
        count++;
      }
    }

    for (let x = 11; x >= 0; x--) {
      const salesPerPlan = salesPercentPlan.innerData[x]?.value;
      if (
        parseFloat(actualdetails[x]["Sales"]) > 0 &&
        parseFloat(salesPerPlan) >= 3
      ) {
        let md = actualdetails[x]["Markdowns"] / actualdetails[x]["Sales"];
        let sales =
          parseFloat(actualdetails[x]["Sales"]) -
          parseFloat(actualdetails[x]["Sales"]) *
            Math.abs(md - annual_markdown / 100);
        if (md > annual_markdown / 100 && sales > 0) {
          data[count] = [];
          data[count]["Sales"] = sales;
          data[count]["SalesPercentPlan"] = parseFloat(salesPerPlan) / 100;
          count++;
        } else if (md <= annual_markdown / 100) {
          data[count] = [];
          data[count]["Sales"] = parseFloat(actualdetails[x]["Sales"]);
          data[count]["SalesPercentPlan"] = parseFloat(salesPerPlan) / 100;
          count++;
        }
        if (count > 6) {
          break;
        }
      }
    }

    if (typeof data[1] !== "undefined") {
      let lg1 = getBaseLog(100, 100 / data[1]["SalesPercentPlan"]);
      calc[0] = (data[1]["Sales"] * lg1) / data[1]["SalesPercentPlan"];
    }
    if (typeof data[2] !== "undefined") {
      calc[1] =
        (data[1]["Sales"] + data[2]["Sales"]) /
        (data[1]["SalesPercentPlan"] + data[2]["SalesPercentPlan"]);
    }
    if (typeof data[3] !== "undefined") {
      calc[2] =
        (data[1]["Sales"] + data[2]["Sales"] + data[3]["Sales"]) /
        (data[1]["SalesPercentPlan"] +
          data[2]["SalesPercentPlan"] +
          data[3]["SalesPercentPlan"]);
    }
    if (typeof data[2] !== "undefined") {
      let lg2 = getBaseLog(100, 100 / data[2]["SalesPercentPlan"]);
      calc[3] = (data[2]["Sales"] * lg2) / data[2]["SalesPercentPlan"];
    }
    if (typeof data[3] !== "undefined") {
      calc[4] =
        (data[2]["Sales"] + data[3]["Sales"]) /
        (data[2]["SalesPercentPlan"] + data[3]["SalesPercentPlan"]);
    }
    if (typeof data[4] !== "undefined") {
      calc[5] =
        (data[2]["Sales"] + data[3]["Sales"] + data[4]["Sales"]) /
        (data[2]["SalesPercentPlan"] +
          data[3]["SalesPercentPlan"] +
          data[4]["SalesPercentPlan"]);
    }
    if (typeof data[3] !== "undefined") {
      let lg3 = getBaseLog(100, 100 / data[3]["SalesPercentPlan"]);
      calc[6] = (data[3]["Sales"] * lg3) / data[3]["SalesPercentPlan"];
    }
    if (typeof data[4] !== "undefined") {
      calc[7] =
        (data[3]["Sales"] + data[4]["Sales"]) /
        (data[3]["SalesPercentPlan"] + data[4]["SalesPercentPlan"]);
    }
    if (typeof data[5] !== "undefined") {
      calc[8] =
        (data[3]["Sales"] + data[4]["Sales"] + data[5]["Sales"]) /
        (data[3]["SalesPercentPlan"] +
          data[4]["SalesPercentPlan"] +
          data[5]["SalesPercentPlan"]);
    }
    if (typeof data[4] !== "undefined") {
      let lg4 = getBaseLog(100, 100 / data[4]["SalesPercentPlan"]);
      calc[9] = (data[4]["Sales"] * lg4) / data[4]["SalesPercentPlan"];
    }
    if (typeof data[5] !== "undefined") {
      calc[10] =
        (data[4]["Sales"] + data[5]["Sales"]) /
        (data[4]["SalesPercentPlan"] + data[5]["SalesPercentPlan"]);
    }
    if (typeof data[6] !== "undefined") {
      calc[11] =
        (data[4]["Sales"] + data[5]["Sales"] + data[6]["Sales"]) /
        (data[4]["SalesPercentPlan"] +
          data[5]["SalesPercentPlan"] +
          data[6]["SalesPercentPlan"]);
    }
    calc = calc.filter((n) => n);
    let calc_amt = Math.round(mypercentile(calc, planData.percentile));
    return calc_amt;
  };

  const mypercentile = (data, percentile) => {
    let p = 0;
    if (0 < percentile && percentile < 1) {
      p = percentile;
    } else if (1 < percentile && percentile <= 100) {
      p = percentile * 0.01;
    } else {
      return 0;
    }
    let count = data.length;
    if (count == 0) {
      return 0;
    }
    let allindex = (count - 1) * p + 1;
    let intvalindex = parseInt(allindex);
    let floatval = allindex - intvalindex;
    data.sort(function (a, b) {
      return a - b;
    });
    let result = 0;
    if (isInt(floatval)) {
      if (typeof data[intvalindex] !== "undefined") {
        result = data[intvalindex];
      } else {
        result = data[intvalindex - 1];
      }
    } else {
      if (count > intvalindex + 1)
        result =
          floatval * (data[intvalindex + 1] - data[intvalindex]) +
          data[intvalindex];
      else if (typeof data[intvalindex] !== "undefined") {
        result = data[intvalindex];
      } else {
        result = data[intvalindex - 1];
      }
    }
    return result;
  };

  const isInt = (value) => {
    var x;
    if (isNaN(value)) {
      return false;
    }
    x = parseFloat(value);
    return (x | 0) === x;
  };

  const markdownsUpdate = (tableKey, rowKey, colIndex, bulkUpdate = false) => {
    const _tableData = cloneDeep(tableData);
    const targetRow = _tableData.get(tableKey).get(rowKey);
    const targetRowInnerData = targetRow.innerData;
    const markdownsPlan = _tableData.get("Markdowns").get("MarkdownsPlan");
    const markdownsPlanInnerData = markdownsPlan.innerData;
    const markdownsPlanVsHistorical = _tableData
      .get("Markdowns")
      .get("markdowns_plan_vs_historical").innerData;
    const { value, current } = targetRowInnerData[colIndex];
    if (value != current) {
      targetRowInnerData[colIndex].current = value;
      const annualSalesAmt = annualForm.SalesAnnualPlan.value;
      const annualMarkdownPer =
        annualForm.MarkdownsAnnualPercentPlan.value / 100;
      const annualMarkdownAmt = +(annualSalesAmt * annualMarkdownPer).toFixed(
        0
      );
      const markdownPlanAmt = +(annualMarkdownAmt * (value / 100)).toFixed(0);
      markdownsPlanInnerData[colIndex].value = markdownPlanAmt;
      markdownsPlanVsHistorical[colIndex].value = planData.plandetails[colIndex]
        .avg_markdowns_plan
        ? (
            (markdownsPlanInnerData[colIndex].value /
              planData.plandetails[colIndex].avg_markdowns_plan) *
            100
          ).toFixed(2)
        : 0;
      if (!bulkUpdate) {
        setTableData(_tableData);
      } else {
        tableData = _tableData;
      }
    }
  };

  const stocktosalesplanUpdate = (
    tableKey,
    rowKey,
    colIndex,
    bulkUpdate = false
  ) => {
    const _tableData = cloneDeep(tableData);
    const targetRow = _tableData.get(tableKey).get(rowKey);
    const targetRowInnerData = targetRow.innerData;
    const inventoryBomPlan = _tableData
      .get("Inventory")
      .get("InventoryBomPlan");
    const inventoryBomPlanInnerData = inventoryBomPlan.innerData;
    const salesPlan = _tableData.get("Sales").get("SalesPlan");
    const salesPlanInnerData = salesPlan.innerData;
    const markdownsPlan = _tableData.get("Markdowns").get("MarkdownsPlan");
    const markdownsPlanInnerData = markdownsPlan.innerData;
    const _annualForm = cloneDeep(annualForm);

    const { value, current } = targetRowInnerData[colIndex];
    if (value != current) {
      targetRowInnerData[colIndex].current = value;

      if (colIndex > 0 && colIndex < 12) {
        const prevIndex = colIndex - 1;
        const p_inv = inventoryBomPlanInnerData[prevIndex].value;
        const p_sales = salesPlanInnerData[prevIndex].value;
        const p_markdowns = markdownsPlanInnerData[prevIndex].value;
        const p_remainder = p_inv - p_sales - p_markdowns;
        const sales = salesPlanInnerData[colIndex].value;
        const inv = sales * value;
        if (p_remainder > inv) {
          inventoryBomPlanInnerData[colIndex].value = +p_remainder.toFixed(0);
          inventoryBomPlanInnerData[colIndex].current = +p_remainder.toFixed(0);
        } else {
          inventoryBomPlanInnerData[colIndex].value = +inv.toFixed(0);
          inventoryBomPlanInnerData[colIndex].current = +inv.toFixed(0);
        }
      } else if (colIndex == 12) {
        const prevIndex = colIndex - 1;
        const p_inv = inventoryBomPlanInnerData[prevIndex].value;
        const p_sales = salesPlanInnerData[prevIndex].value;
        const p_markdowns = markdownsPlanInnerData[prevIndex].value;
        const p_remainder = p_inv - p_sales - p_markdowns;
        const sales = salesPlanInnerData[0].value;
        const inv = sales * value;
        if (p_remainder > inv) {
          inventoryBomPlanInnerData[colIndex].value = +p_remainder.toFixed(0);
          inventoryBomPlanInnerData[colIndex].current = +p_remainder.toFixed(0);
        } else {
          inventoryBomPlanInnerData[colIndex].value = +inv.toFixed(0);
          inventoryBomPlanInnerData[colIndex].current = +inv.toFixed(0);
        }
      } else if (colIndex == 0 && value > 0) {
        const sales = salesPlanInnerData[0].value;
        const inv = +(sales * value).toFixed(0);
        inventoryBomPlanInnerData[colIndex].value = inv;
        inventoryBomPlanInnerData[colIndex].current = inv;
      } else if (colIndex == 0 && value <= 0) {
        inventoryBomPlanInnerData[colIndex].value = 0;
        inventoryBomPlanInnerData[colIndex].current = 0;
      }
      targetRow.innerData = targetRowInnerData;
      inventoryBomPlan.innerData = inventoryBomPlanInnerData;
      PlanCalculatorService.updateStockToSales(_tableData);
      PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
      PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
      PlanCalculatorService.updateTurn(_tableData, _annualForm);
      if (!bulkUpdate) {
        setTableData(_tableData);
        setAnnualForm(_annualForm);
      } else {
        tableData = _tableData;
        annualForm = _annualForm;
      }
    }
  };

  const inventoryUpdate = (tableKey, rowKey, colIndex, bulkUpdate = false) => {
    const _tableData = cloneDeep(tableData);
    const targetRow = _tableData.get(tableKey).get(rowKey);
    const salesPlan = _tableData.get("Sales").get("SalesPlan");
    const markdownsPlan = _tableData.get("Markdowns").get("MarkdownsPlan");
    const innerData = targetRow.innerData;
    const { value, current } = innerData[colIndex];
    const _annualForm = cloneDeep(annualForm);
    if (current != value) {
      innerData[colIndex].current = value;
      if (colIndex > 0) {
        const prevIndex = colIndex - 1;
        const p_inv = innerData[prevIndex].value;
        const p_sales = salesPlan.innerData[prevIndex].value;
        const p_markdowns = markdownsPlan.innerData[prevIndex].value;
        const p_remainder = p_inv - p_sales - p_markdowns;
        if (p_remainder > value) {
          innerData[colIndex].value = p_remainder;
        }
      }
      targetRow.innerData = innerData;
    }
    PlanCalculatorService.updateStockToSales(_tableData);
    PlanCalculatorService.updateInflowRetail(_tableData, _annualForm);
    PlanCalculatorService.updateInflowCost(_tableData, _annualForm);
    PlanCalculatorService.updateTurn(_tableData, _annualForm);
    if (!bulkUpdate) {
      setTableData(_tableData);
      setAnnualForm(_annualForm);
    } else {
      tableData = _tableData;
      annualForm = _annualForm;
    }
  };

  const nextLocation = () => {
    const classesWithSameLocation = _.sortBy(
      clientBasicData.all_live_plans.filter((item) => {
        return (
          item.client_class_id == clientBasicData.current_plan.client_class_id
        );
      }),
      "client_class_id"
    );
    let nextIndex = 0;
    classesWithSameLocation.forEach((item, index) => {
      if (
        item.client_location_id ==
        clientBasicData.current_plan.client_location_id
      ) {
        nextIndex = ++index;
      }
    });
    nextIndex = nextIndex >= classesWithSameLocation.length ? 0 : nextIndex;
    onOptionValueChange({
      location_id: classesWithSameLocation[nextIndex].client_location_id,
      class_id: classesWithSameLocation[nextIndex].client_class_id,
    });
  };

  const nextClass = () => {
    const locationsWithSameClass = _.sortBy(
      clientBasicData.all_live_plans.filter((item) => {
        return (
          item.client_location_id ==
          clientBasicData.current_plan.client_location_id
        );
      }),
      "client_location_id"
    );
    let nextIndex = 0;
    locationsWithSameClass.forEach((item, index) => {
      if (
        item.client_class_id == clientBasicData.current_plan.client_class_id
      ) {
        nextIndex = ++index;
      }
    });
    nextIndex = nextIndex >= locationsWithSameClass.length ? 0 : nextIndex;
    onOptionValueChange({
      location_id: locationsWithSameClass[nextIndex].client_location_id,
      class_id: locationsWithSameClass[nextIndex].client_class_id,
    });
  };

  const getSubmitData = () => {
    const requestData = PlanCalculatorService.getSubmitData(
      planData,
      annualForm,
      tableData,
      null,
      true
    );
    return requestData;
  };

  const getBaseLog = (x, y) => {
    return Math.log(x) / Math.log(y);
  };

  const updateTargetTableDataFromPlanData = () => {
    if (!tableData.size)
      return;
    const _tableData = tableData;
    let total_sales = parseInt(
      _tableData.get("Sales").get("SalesPlan").summary
    );
    let total_sales_actual = parseInt(
        _tableData.get("Sales").get("Sales").summary
    );
    let total_inflow_cost = parseInt(
      _tableData.get("Inventory").get("InflowPlanCost").summary
    );
    let total_inflow_cost_actual = parseInt(
        _tableData.get("Inventory").get("InflowCost").summary
    );
    let total_inflow_retail = parseInt(
      _tableData.get("Inventory").get("InflowPlanRetail").summary
    );
    let total_inflow_retail_actual = parseInt(
      _tableData.get("Inventory").get("InflowRetail").summary
    );
    let total_markdowns = parseInt(
      _tableData.get("Markdowns").get("MarkdownsPlan").summary
    );
    let total_markdowns_actual = parseInt(
      _tableData.get("Markdowns").get("Markdowns").summary
    );

    // updateTargetCash
    let target_cash = 0;
    if (total_sales > 0) {
      target_cash = (
        ((total_sales - total_inflow_cost) / total_sales) *
        100
      ).toFixed(2);
    }

    let target_cash_actual = 0;
    if (total_sales_actual > 0) {
      target_cash_actual = (
          ((total_sales_actual - total_inflow_cost_actual) / total_sales_actual) *
          100
      ).toFixed(2);
    }

    // updateTargetGmroi
    let imu = parseFloat(annualForm.ImuPlan.value);
    let avg_inv = _tableData.get("Inventory").get("InventoryBomPlan").avg;
    let avg_inv_actual = _tableData.get("Inventory").get("InventoryBom").avg;
    // let avg_inv_cost = avg_inv * (1 - (imu / 100));

    let _mmu =
      (total_inflow_retail - total_inflow_cost) / total_inflow_retail -
      (total_markdowns / total_sales) *
        (1 - (total_inflow_retail - total_inflow_cost) / total_inflow_retail);

    let _mmu_actual =
        (total_inflow_retail_actual - total_inflow_cost_actual) / total_inflow_retail_actual -
        (total_markdowns_actual / total_sales_actual) *
        (1 - (total_inflow_retail_actual - total_inflow_cost_actual) / total_inflow_retail_actual);

    let gmroi = (total_sales * (_mmu*100)) / avg_inv * (1 - (imu / 100));
    let gmroi_actual = (total_sales_actual * (_mmu_actual*100)) / avg_inv_actual * (1 - (imu / 100));

    if (!_.isFinite(parseFloat(gmroi))) {
      gmroi = 0;
    }

    if (!_.isFinite(parseFloat(gmroi_actual))) {
      gmroi_actual = 0;
    }

    // updateTargetCmroi
    let cmroi = (total_sales - total_inflow_cost) / avg_inv * (1 - (imu / 100));
    let cmroi_actual = (total_sales_actual - total_inflow_cost_actual) / avg_inv_actual * (1 - (imu / 100));
    if (!_.isFinite(parseFloat(cmroi))) {
      cmroi = 0;
    }

    if (!_.isFinite(parseFloat(cmroi_actual))) {
      cmroi_actual = 0;
    }

    // updateTargetMmu
    if (isNaN(_mmu)) {
      _mmu = 0;
    }

    if (isNaN(_mmu_actual)) {
      _mmu_actual = 0;
    }

    // update all tabs which don't require specific target data
    const _tabs = cloneDeep(tabs);
    // Targets
    // _tabs[0].values[0][1] = planData.target_cash_margins.actual_cash_margin;
    _tabs[0].values[0][1] = target_cash_actual;
    // _tabs[0].values[1][1] = planData.target_cash_margins.actual_gmroi;
    _tabs[0].values[1][1] = gmroi_actual.toFixed(2);
    // _tabs[0].values[2][1] = planData.target_cash_margins.actual_cmroi;
    _tabs[0].values[2][1] = cmroi_actual.toFixed(2);
    // _tabs[0].values[3][1] = planData.target_cash_margins.actual_mmu;
    _tabs[0].values[3][1] = (_mmu_actual * 100).toFixed(0);
    _tabs[0].values[0][2] = target_cash;
    _tabs[0].values[1][2] = gmroi.toFixed(2);
    _tabs[0].values[2][2] = cmroi.toFixed(2);
    _tabs[0].values[3][2] = (_mmu * 100).toFixed(0);

    // Trend
    _tabs[1].values[0][3] = _tableData
      .get("Sales")
      .get("SalesPlan").innerData[0]?.value;
    _tabs[1].values[1][3] = _tableData
      .get("Markdowns")
      .get("MarkdownsPlan").innerData[0]?.value;
    _tabs[1].values[2][3] = _tableData
      .get("Inventory")
      .get("InventoryBomPlan").innerData[1]?.value;
    _tabs[1].values[4][2] = calcTrendV3(_tableData, annualForm, true);

    setTabs(_tabs);
  };

  /**
   * Reload target tabs when full target data is provided
   * Requires that planData has already been put into some tabs (see updateTargetTableDataFromPlanData)
   *
   * Split out to prevent data overwrite when targets are set on planData
   *
   * @returns void
   */
  const reloadTargetTabs = () => {
    if (!targets || !planData) {
      return;
    }

    const _tabs = cloneDeep(tabs);

    // Trend
    _tabs[1].values[0][1] = targets.MTD?.Sales;
    _tabs[1].values[0][2] = targets.MTD?.SalesTrend;
    _tabs[1].values[0][4] = _tabs[1].values[0][3]
      ? ((targets.MTD?.SalesTrend / _tabs[1].values[0][3]) * 100).toFixed(1) +
        "%"
      : null;
    _tabs[1].values[1][1] = targets.MTD?.Markdowns;
    _tabs[1].values[1][2] = targets.MTD?.MarkdownsTrend;
    _tabs[1].values[1][4] = _tabs[1].values[1][3]
      ? ((targets.MTD?.MarkdownsTrend / _tabs[1].values[1][3]) * 100).toFixed(
          1
        ) + "%"
      : null;
    _tabs[1].values[2][1] = targets.MTD?.Inv;
    _tabs[1].values[2][2] = targets.MTD?.InvTrend;
    _tabs[1].values[2][4] = _tabs[1].values[2][3]
      ? ((targets.MTD?.InvTrend / _tabs[1].values[2][3]) * 100).toFixed(1) + "%"
      : null;
    _tabs[1].values[3][2] = targets.MTD?.Calc1;
    _tabs[1].values[5][2] = targets.MTD?.LinearSales;
    _tabs[1].values[6][2] = targets.MTD?.L12;
    // $('#mtd_datathroughdate').text("DataThroughDate " + data.DataThroughDate);

    // 30 days
    _tabs[2].values[0][1] = targets["30"]?.Cash;
    _tabs[2].values[0][2] = targets["30"]?.CashPlan;
    _tabs[2].values[1][1] = targets["30"]?.inflowRetail;
    _tabs[2].values[1][2] = targets["30"]?.InflowPlanRetail;
    _tabs[2].values[2][1] = targets["30"]?.Sales;
    _tabs[2].values[2][2] = targets["30"]?.SalesPlan;
    _tabs[2].values[3][1] = targets["30"]?.Markdowns;
    _tabs[2].values[3][2] = targets["30"]?.MarkdownsPlan;
    _tabs[2].values[4][1] = targets["30"]?.Freshness;
    // 60 days
    _tabs[3].values[0][1] = targets["60"]?.Cash;
    _tabs[3].values[0][2] = targets["60"]?.CashPlan;
    _tabs[3].values[1][1] = targets["60"]?.inflowRetail;
    _tabs[3].values[1][2] = targets["60"]?.InflowPlanRetail;
    _tabs[3].values[2][1] = targets["60"]?.Sales;
    _tabs[3].values[2][2] = targets["60"]?.SalesPlan;
    _tabs[3].values[3][1] = targets["60"]?.Markdowns;
    _tabs[3].values[3][2] = targets["60"]?.MarkdownsPlan;
    _tabs[3].values[4][1] = targets["60"]?.Freshness;
    // 90 days
    _tabs[4].values[0][1] = targets["90"]?.Cash;
    _tabs[4].values[0][2] = targets["90"]?.CashPlan;
    _tabs[4].values[1][1] = targets["90"]?.inflowRetail;
    _tabs[4].values[1][2] = targets["90"]?.InflowPlanRetail;
    _tabs[4].values[2][1] = targets["90"]?.Sales;
    _tabs[4].values[2][2] = targets["90"]?.SalesPlan;
    _tabs[4].values[3][1] = targets["90"]?.Markdowns;
    _tabs[4].values[3][2] = targets["90"]?.MarkdownsPlan;
    _tabs[4].values[4][1] = targets["90"]?.Freshness;

    setTabs(_tabs);
  };

  const updateCostOfPurchase = () => {
    if (!tableData.length)
      return;
    const _annualForm = annualForm;
    let annual_sales = parseInt(
      removeCommas(`${_annualForm.SalesAnnualPlan.value}`)
    );
    let total_inflow_cost_plan = parseInt(
      removeCommas(
        `${tableData.get("Inventory").get("InflowPlanCost").summary}`
      )
    );
    let cost_of_purchase = 0;
    if (annual_sales > 0) {
      cost_of_purchase = (
        (total_inflow_cost_plan / annual_sales) *
        100
      ).toFixed(2);
    }
    setCostOfPurchase(cost_of_purchase);
  };

  const waitingPlan = async (planId, planVersion) => {
    const interval = setInterval(async () => {
      let plan = await PlanService.checkPlan(client.Id, planId, planVersion);
      if (Object.keys(plan).length) {
        clearInterval(interval);
        if (parseInt(plan.version) === 1000) {
          onFutureCreated();
        }
        goToNextPlan(plan);
      }
    }, 10000);

    intervalRef.current = interval;
  };

  const goToNextPlan = (plan) => {
    if (planData.planid == plan.plan_id) {
      window.location.reload();
    }
    navigate(
      `/clients/${client.ClientCode}/live-plan/${plan.plan_id}/${plan.plan_version}`
    );
  };

  const updatePlan = async (future) => {
    try {
      let hasMinusInflow = false;
      tableData
        .get("Inventory")
        .get("InflowPlanRetail")
        .innerData.map((item) => {
          if (item.value < 0 || item.current < 0) {
            hasMinusInflow = true;
          }
        });

      if (hasMinusInflow) {
        messageContext.show("Negative inflow error!");
        setSingleSaving(false);
        return false;
      }
      setSingleSaving(true);
      let requestData = getSubmitData();
      const newPlan = await PlanService.savePlan(
        client.Id,
        clientBasicData.current_plan.plan_id,
        requestData,
        clientBasicData.current_plan.plan_version,
        future
      );

      messageContext.showSuccess("Updated. Waiting to create a new plan");
      waitingPlan(newPlan.result.plan_id, newPlan.result.plan_version);
      // navigate(`/clients/${client.ClientCode}/live-plan/${newPlan.result.plan_id}/${newPlan.result.plan_version}`)
    } catch (e) {
      messageContext.show(e.response?.data?.message);
    }
    setSingleSaving(false)
  };

  const reloadPlan = () => {
    initPlanData();
  };

  const clearCache = () => {
    loadLivePlanDetail(true);
  };

  const toggleHistoricalData = async (yearmonth) => {
    if (historicalPeriodDatas.find((item) => item.yearmonth == yearmonth)) {
      let cloneHistoricalPeriodDatas = [];
      historicalPeriodDatas.forEach((item) => {
        if (item.yearmonth != yearmonth) {
          cloneHistoricalPeriodDatas.push(item);
        }
      });
      setHistoricalPeriodDatas(cloneHistoricalPeriodDatas);
    } else {
      try {
        setLoadingHistoricalData(true);
        const _history = clientBasicData.historical_periods.find(
          (item) => item.yearmonth == yearmonth
        );
        let data = await PlanService.getLivePlanDetail(
          client.Id,
          _history.plan_id,
          _history.plan_version
        );
        data["yearmonth"] = yearmonth;
        let cloneHistoricalPeriodDatas = _.clone(historicalPeriodDatas);
        cloneHistoricalPeriodDatas.push(data);
        cloneHistoricalPeriodDatas = _.sortBy(
          cloneHistoricalPeriodDatas,
          "yearmonth"
        );
        setHistoricalPeriodDatas(cloneHistoricalPeriodDatas);
      } catch (e) {}
      setLoadingHistoricalData(false);
    }
  };

  const confirmLoadFromPlan = async ({ type, locationClass }) => {
    if (locationClass) {
      try {
        setCopyFromLoading(true);
        // Recalculate all data
        const data = await PlanService.getLocationClassLivePlanDetail(
          client.Id,
          locationClass.id,
          "forLivePlan"
        );
        // Only replace input datas
        const _tableData = cloneDeep(tableData);
        [
          ["Sales", "SalesPercentPlan"],
          ["Inventory", "StockToSalesPlan"],
          ["Inventory", "InventoryBomPlan"],
          ["Markdowns", "MarkdownsPercentPlan"],
        ].forEach((rowItem) => {
          data.plandetails.forEach((item, index) => {
            const _row = _tableData.get(rowItem[0]).get(rowItem[1]);
            const updatedInnerData = _row.innerData;
            updatedInnerData[index].value = item[rowItem[1]];
            _row.innerData = updatedInnerData;
          });
        });
        triggerRecalculate.current = true;
        setTableData(_tableData);
        setDisplayCopyFromModal(false);
      } catch (e) {
        messageContext.show(e.response?.data?.message);
      }
      setCopyFromLoading(false);
    }
  };

  const recalculateAllData = () => {
    for (let i = 0; i <= 11; i++) {
      markdownsUpdate("Markdowns", "MarkdownsPercentPlan", i, i != 12);
    }
    for (let i = 0; i <= 11; i++) {
      salesUpdate("Sales", "SalesPercentPlan", i, i != 11);
    }
    for (let i = 0; i <= 12; i++) {
      stocktosalesplanUpdate("Inventory", "StockToSalesPlan", i, i != 12);
    }
    for (let i = 0; i <= 12; i++) {
      inventoryUpdate("Inventory", "InventoryBomPlan", i, i != 12);
    }
    triggerRecalculate.current = false;
  };

  const showCopyToModal = () => {
    setDisplayCopyToModal(true);
  };

  const hotKeysContext = {
    clientBasicData,
    updatePlan,
    nextLocation,
    nextClass,
    setDisplayCopyFromModal,
    showCopyToModal,
    getKFlow,
    reloadPlan,
    clearCache,
    createFuturePlan,
    removeFuturePlan,
    toggleCopyPanel,
    toggleTargetPanel,
    toggleGraphPanel,
    toggleHeaderPanel,
  };
  const copyActuals = (doSales, doMarkdown) => {
    let _tableData = cloneDeep(tableData);
    let valueChange = false;
    if (doSales) {
      valueChange = true;
      const actualSales = _tableData.get("Sales").get("SalesPercent");
      const planSales = _tableData.get("Sales").get("SalesPercentPlan");
      if (actualSales && planSales) {
        planSales.innerData = actualSales.innerData.map((val) => ({
          value: parseFloat(val.value.toFixed(2)),
          orig: val.orig,
          current: val.current,
        }));
        planSales.innerData[12] = tableData.get("Sales").get("SalesPercentPlan").innerData[12];
        planSales.summary = actualSales.summary;
      }
    }
    if (doMarkdown) {
      valueChange = true;
      const actualMarkdown = _tableData.get("Markdowns").get("MarkdownsPercent");
      const planMarkdown = _tableData.get("Markdowns").get("MarkdownsPercentPlan");
      if (actualMarkdown && planMarkdown) {
        planMarkdown.innerData = actualMarkdown.innerData.map((val) => ({
          value: parseFloat(val.value.toFixed(2)),
          orig: val.orig,
          current: val.current,
        }));
        planMarkdown.innerData[12] = tableData.get("Markdowns").get("MarkdownsPercentPlan").innerData[12];
        planMarkdown.summary = actualMarkdown.summary;
      }
    }

    if (valueChange) {
      triggerRecalculate.current = true;
      setTableData(_tableData);
    }
  }

  const copyBothActuals = () => {
    copyActuals(true, true);
  }

  const copySaleActualsToPlan = () => {
    copyActuals(true, false);
  };

  const copyMarkdownActualsToPlan = () => {
    copyActuals(false, true);
  };

  if (loading) {
    return (
      <Box sx={ModifyStyle.loadingSection}>
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }

  if (!loading && loadError) {
    return (
      <Box sx={ModifyStyle.loadingSection}>
        <Box>
          <GppMaybeOutlinedIcon sx={{ fontSize: 50 }} />
        </Box>
        <Box>{loadError}</Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={ModifyStyle.leftSection}>
        <Box sx={ModifyStyle.leftContent}>
          <Box sx={{paddingLeft: '10px', mt: 2, pt: 1, fontSize: '12px'}}>
            Date created: {moment(planData?.plan_info.created_date.date).format('Y-MM-DD')} <br/>
            Source: {planData?.plan_info.source}
          </Box>
          <Box sx={ModifyStyle.loadPlan}>
            <ButtonGroup id={'left-button-group'}
              variant="outlined"
              size="small"
              sx={{ mb: 2 }}
              aria-label="outlined button group"
            >
              <Button variant={showLoadCopyContainer ? "contained" : "outlined"} onClick={() => toggleCopyPanel()}><ContentCopyIcon  sx={{ fontSize: '12px'}} />  Copy</Button>
              <Button variant={showTargetContainer ? "contained" : "outlined"} onClick={() => toggleTargetPanel()}><TrackChangesIcon  sx={{ fontSize: '12px' }} />  Target</Button>
              <Button variant={showGraphContainer ? "contained" : "outlined"} onClick={() => toggleGraphPanel()}><InsertChartIcon  sx={{ fontSize: 'small' }} />  Chart</Button>
            </ButtonGroup>
            {showLoadCopyContainer &&  <Box id={'load-copy-container'}>
              <Button
                onClick={() => setDisplayCopyFromModal(true)}
                fullWidth
                variant="outlined"
              >
                Load Plan
              </Button>
              <PlanCopyFromModal
                open={displayCopyFromModal}
                type="live"
                loading={copyFromLoading}
                onClose={() => setDisplayCopyFromModal(false)}
                onConfirm={confirmLoadFromPlan}
                defaultClassId={clientBasicData?.current_plan?.LocationClassId}
              />
              {!clientBasicData.current_plan.is_future_plan ? (
                <>
                  <LoadingButton
                    onClick={showCopyToModal}
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Copy To
                  </LoadingButton>
                  <PlanCopyToModal
                    open={displayCopyToModal}
                    annualform={annualForm}
                    tabledata={tableData}
                    plandata={planData}
                    buildtype="live_plan"
                    onClose={() => setDisplayCopyToModal(false)}
                  />
                  <LoadingButton
                    onClick={() => setFuturePlanModalOpen(!futurePlanModalOpen)}
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Edit Future Open Plan
                  </LoadingButton>
                  <FuturePlanEditModal
                    open={futurePlanModalOpen}
                    onClose={() => setFuturePlanModalOpen(false)}
                    planSettingId={clientBasicData.current_plan.plan_id}
                    epochMonth={clientBasicData.current_plan.epochmonth_thisplan}
                  />
                  <LoadingButton
                    onClick={() => setDisplayMassCopyPlanModal(true)}
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 1 }}
                  >
                    Mass Copy Plan Tool
                  </LoadingButton>
                  <MassCopyPlanUploadModal
                    open={displayMassCopyPlanModal}
                    onClose={() => setDisplayMassCopyPlanModal(false)}
                    client={client}
                  />
                </>
              ): null}
            </Box>}
          </Box>
          {!targetsLoading ? (
            <>
              {showTargetContainer && <Box id={'target-container'}>
                <Box sx={ModifyStyle.tabs}>
                  {tabs.map((item) => {
                    const style =
                      item.label === tab
                        ? { ...ModifyStyle.tab, ...ModifyStyle.activeTab }
                        : ModifyStyle.tab;
                    return (
                      <Box
                        onClick={() => onTabChange(item.label)}
                        key={`tab_switch_${item.label}`}
                        sx={style}
                      >
                        {item.label}
                      </Box>
                    );
                  })}
                </Box>

              {tabs.map((_tab) => (
                <Paper
                  key={`tab_table_${_tab.label}`}
                  hidden={_tab.label !== tab}
                  sx={ModifyStyle.transparentPaper}
                >
                  <TableContainer sx={ModifyStyle.tabPanel}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {_tab.titles.map((_item) => {
                            return (
                              <TableCell
                                key={`tab_table_title_${_tab.label}_${_item}`}
                              >
                                {_item}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_tab.values.map((item, index) => {
                          return (
                            <TableRow
                              key={`tab_table_title_${_tab.label}_${index}`}
                            >
                              {item.map((v, k) => {
                                return (
                                  <TableCell
                                    key={`tab_table_title_${_tab.label}_${index}_${k}`}
                                  >
                                    {v}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              ))}
            </Box>}
            </>
          ) : (
            <Box sx={ModifyStyle.loadingSection}>
              <CircularProgress color="primary" size={50} />
            </Box>
          )}
          {showGraphContainer && <Box id={"graph-container"} sx={ModifyStyle.graphContainer}>
            <PlanChart sx={{ mt: 2 }} displayMonths={displayMonths} tableData={tableData} />
          </Box>}
        </Box>
      </Box>

      <Box sx={ModifyStyle.rightSection}>
        <Grid container direction="column" sx={ModifyStyle.searchArea}>
          {showHeaderContainer && <Grid item container spacing={2}>
            {Object.keys(annualForm).map((key) => {
              const searchItem = annualForm[key];
              return (
                <Grid key={key} item xs={12} sm={6} md={3}>
                  <TextField
                    onChange={({ target }) =>
                      updateAnnualForm(key, { value: target.value })
                    }
                    onBlur={(e) => onAnnualFormBlur(key, searchItem)}
                    value={searchItem.value}
                    label={searchItem.label}
                    size="small"
                    fullWidth
                  />
                  <Box classes="searchChips">
                    {Object.keys(searchItem.chipProps)?.map((chipKey) => {
                      const chipItem = searchItem.chipProps[chipKey];
                      return (
                        <Chip
                          key={`${key}-${chipKey}`}
                          style={ModifyStyle.chipsItem}
                          size={"small"}
                          onClick={() => onAnnualFormBlur(key, chipItem)}
                          label={`${chipItem.label} ${numeral(
                            chipItem.value
                          ).format("0,0.00")}`}
                          sx={{ mt: 1, mr: 1 }}
                        />
                      );
                    })}
                  </Box>
                </Grid>
              );
            })}
          </Grid>}
          <Grid item container sx={ModifyStyle.searchButtons}>
            <Button
              size={"small"}
              variant="contained"
              sx={{ mr: 2 }}
              onClick={clearCache}
            >
              Clear Cache
            </Button>
            <Button
              size={"small"}
              variant="contained"
              sx={{ mr: 2 }}
              onClick={reloadPlan}
            >
              UNDO / RELOAD
            </Button>
            <Button
              size={"small"}
              variant="contained"
              sx={{ mr: 2 }}
              onClick={getKFlow}
            >
              K-FLOW
            </Button>
            <Tooltip title={"Copy Sales & Markdown Actuals % to Plan"}>
              <Button
                size={"small"}
                variant="contained"
                sx={{ mr: 2 }}
                onClick={copyBothActuals}
              >
                COPY ACTUALS
              </Button>
            </Tooltip>
            <ButtonGroup variant="outlined" sx={{ mr: 2 }}>
              {[1, 2, 3].map((backYear) => {
                const _year = moment().subtract(backYear, "years");
                const _yearMonth = _year.format("YYYYMM");
                return (
                  <LoadingButton
                    key={`backYear${backYear}`}
                    loading={loadingHistoricalData}
                    variant={
                      historicalPeriodDatas.find(
                        (item) => item.yearmonth == _yearMonth
                      )
                        ? "contained"
                        : "outlined"
                    }
                    disabled={
                      _.pluck(
                        clientBasicData?.historical_periods,
                        "yearmonth"
                      ).indexOf(_yearMonth) == -1
                    }
                    onClick={() => toggleHistoricalData(_yearMonth)}
                  >
                    {_year.format("YYYY")}
                  </LoadingButton>
                );
              })}
            </ButtonGroup>
          </Grid>
        </Grid>
        <Box sx={{ pb: 7 }}>
          <HotKeysContext.Provider value={hotKeysContext}>
            <BigTable
              tableData={tableData}
              historicalPeriodDatas={historicalPeriodDatas}
              displayMonths={displayMonths}
              colorMap={colorMap}
              onInputBlur={(tableKey, innerKey, valIndex) =>
                onBlurTableForm(tableKey, innerKey, valIndex)
              }
              onInputChange={(e, tableKey, innerKey, valIndex) =>
                updateTableForm(e, tableKey, innerKey, valIndex)
              }
              copySaleActualsToPlan={copySaleActualsToPlan}
              copyMarkdownActualsToPlan={copyMarkdownActualsToPlan}
            />
          </HotKeysContext.Provider>
        </Box>
        <Box
          sx={{
            py: 1,
            pl: 1,
            position: "fixed",
            bottom: 0,
            backgroundColor: "#f3f4f5",
            borderRadius: "5px 5px 0 0",
            boxShadow: "0 -1px 5px rgb(162 166 187 / 40%)",
          }}
        >
          <LoadingButton
            loading={singleSaving}
            onClick={(e) =>
              updatePlan(clientBasicData.current_plan.is_future_plan)
            }
            variant="contained"
            disabled={singleSaving}
            sx={ModifyStyle.actionButton}
          >
            UPDATE PLAN
          </LoadingButton>
          <Button
            onClick={nextLocation}
            variant="outlined"
            sx={ModifyStyle.actionButton}
          >
            NEXT LOCATION
          </Button>
          <Button
            onClick={nextClass}
            variant="outlined"
            sx={ModifyStyle.actionButton}
          >
            NEXT CLASS
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default LivePlanEdit;
